import instance from "../axios";
import * as endpoints from "./endpoints";
import { AUTHORIZATION_KEY } from "../global_constants";

export const registerUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.REGISTER_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginUser = (payload) => {
  delete instance.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    instance
      .post(endpoints.LOGIN_API_PATH, payload)
      .then((response) => {
        const { data } = response;
        instance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.access_token}`;
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAuthUserDetail = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTHORIZATION_KEY);
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      instance
        .get(endpoints.AUTH_USER_API_PATH)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};

export const getAllTimeCards = () => {
  let path = endpoints.ALL_TIMECARDS_API_PATH;
  return instance.get(path);
}
export const refreshAllTimeCards = (year) => {
  let path = endpoints.REFRESH_ALL_TIMECARDS_API_PATH.replace("{year}", year);
  return instance.get(path);
}


export const getAllBills = () => {
  let path = endpoints.ALL_BILLS_API_PATH;
  return instance.get(path);
}
export const refreshAllBills = () => {
  let path = endpoints.REFRESH_ALL_BILLS_API_PATH;
  return instance.get(path);
}

export const getAllVendors = () => {
  let path = endpoints.ALL_VENDORS_API_PATH;
  return instance.get(path);
}
export const refreshAllVendors = () => {
  let path = endpoints.REFRESH_ALL_VENDORS_API_PATH;
  return instance.get(path);
}
export const getAllRepopulationEligibleVendors = () => {
  let path = endpoints.GET_REPOPULATION_ELIGIBLE_VENDORS_API_PATH;
  return instance.get(path);
}

export const changeEmployeeTypeApiCall = (id, values) => {
  let path = endpoints.CHANGE_VENDOR_EMPLOYEE_TYPE_API_PATH.replace("{id}", id);
  return instance.post(path, values);
}
export const assignQuickbooksVendorApiCall = (id, values) => {
  let path = endpoints.ASSIGN_QUICKBOOKS_VENDOR_API_PATH.replace("{id}", id);
  return instance.post(path, values);
}


export const getSettings = () => {
  let path = endpoints.GET_SETTINGS_API_PATH;
  return instance.get(path);
}

export const editSettings = (payload) => {
  let path = endpoints.EDIT_SETTINGS_API_PATH
  return instance.post(path, payload);
};

export const qbOauthLogin = () => {
  let path = endpoints.QUICKBOOKS_OAUTH_API_PATH
  return instance.get(path);
};


export const populateBillsAction = (year, paycycleStart=null, paycycleEnd=null) => {
  let path = endpoints.POPULATE_BILLS_API_PATH.replace("{year}", year);
  if (paycycleStart && paycycleEnd) {
    path = path + "&paycycle_start=" + paycycleStart + "&paycycle_end=" + paycycleEnd
  }
  console.log(path)
  return instance.get(path);
};
export const repopulateSpecificBill = (vendor_id, paycycle_start, paycycle_end) => {
  let path = endpoints.REPOPULATE_BILLS_API_PATH
    .replace("{vendor_id}", vendor_id)
    .replace("{paycycle_start}", paycycle_start)
    .replace("{paycycle_end}", paycycle_end);
  return instance.get(path);
};
export const deleteWebAppGeneratedBills = () => {
  let path = endpoints.DELETE_APP_GENERATED_BILLS_API_PATH;
  return instance.get(path);
};
export const deleteAllWebAppRecords = () => {
  let path = endpoints.DELETE_ALL_RECORDS_API_PATH;
  return instance.get(path);
}

export const getPayCycleSettings = () => {
  let path = endpoints.GET_PAY_CYCLE_SETTINGS_API_PATH;
  return instance.get(path);
};
export const editPayCycleSettings = (payload) => {
  let path = endpoints.EDIT_PAY_CYCLE_SETTINGS_API_PATH
  return instance.post(path, payload);
};
export const getPayCyclesForRepopulation = (year) => {
  let path = endpoints.GET_PAYCYCLES_FOR_A_YEAR.replace("{year}", year)
  return instance.get(path);
  
}

export const getAllQuickBooksVendors = () => {
  let path = endpoints.ALL_QUICKBOOKS_VENDORS_API_PATH;
  return instance.get(path);
}
export const refreshAllQuickBooksVendors = () => {
  let path = endpoints.REFRESH_ALL_QUICKBOOKS_VENDORS_API_PATH;
  return instance.get(path);
}

export const getBackendLogs = () => {
  let path = endpoints.BACKEND_LOGS_API_PATH;
  return instance.get(path);
}