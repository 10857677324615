import React from "react";
import {
  HomeOutlined,
  SolutionOutlined,
  FieldTimeOutlined,
  ApiOutlined,
  SettingOutlined,
  TeamOutlined,
  AuditOutlined,
  CodepenOutlined,
} from "@ant-design/icons";

import NotFoundPage from "./pages/404Page";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";
import RegisterPage from "./pages/register";

const HomePageComponent = React.lazy(() =>
  import("./pages/homePage")
);

const TimecardsPage = React.lazy(() =>
  import("./pages/timecards")
);

const BillsPage = React.lazy(() =>
  import("./pages/bills")
);

const VendorsPage = React.lazy(() =>
  import("./pages/vendors")
);

const ActionsPage = React.lazy(() =>
  import("./pages/actions")
)

const SettingsPage = React.lazy(() =>
  import("./pages/settings")
)

const QuickbooksVendorsPage = React.lazy(() =>
  import("./pages/quickbooks_vendors")
)
const PortalHealthStatusPage = React.lazy(() =>
  import("./pages/portalHealthStatus")
)

export const REGISTER_ROUTE = "/register";

export const INDEX_ROUTE = "/";

export const TIMECARDS_ROUTE = "/timecards";

export const BILLS_ROUTE = "/bills";

export const QUICKBOOKS_VENDORS_ROUTE = "/quickbooks-vendors";

export const VENDORS_ROUTE = "/vendors";

export const ACTIONS_ROUTE = "/actions";

export const SETTINGS_ROUTE = "/settings";

export const PORTAL_HEALTH_ROUTE = "/portal-health";

// ROUTES
export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  // {
  //   exact: true,
  //   name: "register",
  //   path: REGISTER_ROUTE,
  //   component: RegisterPage,
  // },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponent,
      },
      {
        exact: true,
        name: "timecards",
        path: TIMECARDS_ROUTE,
        component: TimecardsPage,
      },
      {
        exact: true,
        name: "bills",
        path: BILLS_ROUTE,
        component: BillsPage,
      },
      {
        exact: true,
        name: "quickbooks-vendors",
        path: QUICKBOOKS_VENDORS_ROUTE,
        component: QuickbooksVendorsPage,
      },
      {
        exact: true,
        name: "vendors",
        path: VENDORS_ROUTE,
        component: VendorsPage,
      },
      {
        exact: true,
        name: "actions",
        path: ACTIONS_ROUTE,
        component: ActionsPage,
      },
      {
        exact: true,
        name: "portal-health",
        path: PORTAL_HEALTH_ROUTE,
        component: PortalHealthStatusPage,
      },
      {
        exact: true,
        name: "settings",
        path: SETTINGS_ROUTE,
        component: SettingsPage,
      }
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <HomeOutlined />,
  },
  {
    name: "timecards",
    label: "Timecards",
    route: TIMECARDS_ROUTE,
    icon: <FieldTimeOutlined />,
  },
  {
    name: "bills",
    label: "Purchases",
    route: BILLS_ROUTE,
    icon: <SolutionOutlined />,
  },
  {
    name: "quickbooks-vendors",
    label: "QuickBooks Vendors",
    route: QUICKBOOKS_VENDORS_ROUTE,
    icon: <AuditOutlined />,
  },
  {
    name: "vendors",
    label: "HomeBase Vendors",
    route: VENDORS_ROUTE,
    icon: <TeamOutlined />,
  },
  {
    name: "actions",
    label: "Actions",
    route: ACTIONS_ROUTE,
    icon: <ApiOutlined />,
  },
  {
    name: "settings",
    label: "Settings",
    route: SETTINGS_ROUTE,
    icon: <SettingOutlined />,
  },
  {
    name: "portal-health",
    label: "Portal Logs",
    route: PORTAL_HEALTH_ROUTE,
    icon: <CodepenOutlined />,
  }
];
