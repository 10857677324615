import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import {
  accountDataReducer,
  initialState as accountData,
} from "./reducers/accountDataReducer";
import { AccountDataProvider } from "./context/accountData";

import "./styles/style.scss";

if (process.env.REACT_APP_SENTRY_DSN) {
  let traceSampleRate = 1;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing()
    ],
    tracesSampleRate: traceSampleRate,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.'
    ],
  });
}

ReactDOM.render(
  <AccountDataProvider
    initialState={accountData}
    reducer={accountDataReducer}
  >
    <App />
  </AccountDataProvider>,
  document.getElementById("root")
);