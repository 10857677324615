export const REGISTER_API_PATH = "register/new-account";

export const LOGIN_API_PATH = "login";
export const AUTH_USER_API_PATH = "auth_user";

export const TIMECARDS_API_PATH = "time-card";
export const ALL_TIMECARDS_API_PATH = `${TIMECARDS_API_PATH}/all`;
export const REFRESH_ALL_TIMECARDS_API_PATH = `${TIMECARDS_API_PATH}/refresh-from-api?year={year}`;

export const BILLS_API_PATH = "bill";
export const ALL_BILLS_API_PATH = `${BILLS_API_PATH}/all`;
export const REFRESH_ALL_BILLS_API_PATH = `${BILLS_API_PATH}/refresh-from-api`;

export const VENDORS_API_PATH = "vendor";
export const ALL_VENDORS_API_PATH = `${VENDORS_API_PATH}/all`;
export const REFRESH_ALL_VENDORS_API_PATH = `${VENDORS_API_PATH}/refresh-from-api`;
export const CHANGE_VENDOR_EMPLOYEE_TYPE_API_PATH = `${VENDORS_API_PATH}/edit?id={id}`;
export const ASSIGN_QUICKBOOKS_VENDOR_API_PATH = `${VENDORS_API_PATH}/change-assigned-quickbooks-vendor?id={id}`;
export const GET_REPOPULATION_ELIGIBLE_VENDORS_API_PATH = `${VENDORS_API_PATH}/all-repopulation-eligible-vendors`;

export const SETTINGS_API_PATH = "settings";
export const GET_SETTINGS_API_PATH = `${SETTINGS_API_PATH}?id=1`;
export const EDIT_SETTINGS_API_PATH = `${SETTINGS_API_PATH}/edit?id=1`;
export const QUICKBOOKS_OAUTH_API_PATH = `${SETTINGS_API_PATH}/quickbooks-oauth`;

export const ACTIONS_API_PATH = "actions";
export const POPULATE_BILLS_API_PATH = `${ACTIONS_API_PATH}/populate-new-bills?year={year}`;
export const REPOPULATE_BILLS_API_PATH = `${ACTIONS_API_PATH}/repopulate-bills?vendor_id={vendor_id}&paycycle_start={paycycle_start}&paycycle_end={paycycle_end}`;
export const DELETE_APP_GENERATED_BILLS_API_PATH = `${ACTIONS_API_PATH}/delete-all-bills`;
export const DELETE_ALL_RECORDS_API_PATH = `${ACTIONS_API_PATH}/full-data-cleanup`;

export const PAY_CYCLE_SETTINGS_API_PATH = "pay-cycle-settings";
export const GET_PAY_CYCLE_SETTINGS_API_PATH = `${PAY_CYCLE_SETTINGS_API_PATH}?id=1`;
export const EDIT_PAY_CYCLE_SETTINGS_API_PATH = `${PAY_CYCLE_SETTINGS_API_PATH}/edit?id=1`;
export const GET_PAYCYCLES_FOR_A_YEAR = `${PAY_CYCLE_SETTINGS_API_PATH}/pay-cycles-for-year?year={year}`;

export const QUICKBOOKS_VENDORS_API_PATH = "quickbooks-vendor";
export const ALL_QUICKBOOKS_VENDORS_API_PATH = `${QUICKBOOKS_VENDORS_API_PATH}/all`;
export const REFRESH_ALL_QUICKBOOKS_VENDORS_API_PATH = `${QUICKBOOKS_VENDORS_API_PATH}/refresh-from-api`;

export const PORTAL_HEALTH_API_PATH = "health";
export const BACKEND_LOGS_API_PATH = `${PORTAL_HEALTH_API_PATH}/backend-logs`;
